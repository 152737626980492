// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("/vercel/path0/web/src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("/vercel/path0/web/src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-desserts-index-tsx": () => import("/vercel/path0/web/src/pages/desserts/index.tsx" /* webpackChunkName: "component---src-pages-desserts-index-tsx" */),
  "component---src-pages-entrees-index-tsx": () => import("/vercel/path0/web/src/pages/entrees/index.tsx" /* webpackChunkName: "component---src-pages-entrees-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("/vercel/path0/web/src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-finger-food-index-tsx": () => import("/vercel/path0/web/src/pages/finger-food/index.tsx" /* webpackChunkName: "component---src-pages-finger-food-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/vercel/path0/web/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mains-index-tsx": () => import("/vercel/path0/web/src/pages/mains/index.tsx" /* webpackChunkName: "component---src-pages-mains-index-tsx" */),
  "component---src-pages-menu-index-tsx": () => import("/vercel/path0/web/src/pages/menu/index.tsx" /* webpackChunkName: "component---src-pages-menu-index-tsx" */),
  "component---src-pages-price-list-index-tsx": () => import("/vercel/path0/web/src/pages/price-list/index.tsx" /* webpackChunkName: "component---src-pages-price-list-index-tsx" */),
  "component---src-pages-sandwiches-index-tsx": () => import("/vercel/path0/web/src/pages/sandwiches/index.tsx" /* webpackChunkName: "component---src-pages-sandwiches-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path0/web/.cache/data.json")

